import * as React from "react";

import { UserControlPropsType as PropsType } from "components/layout/header/types";

const CLASSNAMES = {
  sign_in: "button--header",
  sign_up: "button--green",
};

export default function LoggedOut(props: PropsType): JSX.Element {
  const { i18n, session_links } = props;

  const LinkListItems = session_links.map(({ href, icon_classname, label }) => (
    <a
      className={`button ${CLASSNAMES[label]} contrast`}
      {...{ href }}
      key={label}
      role="button"
    >
      <i className={`fas fa-${icon_classname}`} />
      <span>{i18n[label]}</span>
    </a>
  ));

  return <div className="user-controls">{LinkListItems}</div>;
}
