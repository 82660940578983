import ReactOnRails from 'react-on-rails';

import * as Desktop from "components/layout/header/desktop";
import * as Mobile from "components/layout/header/mobile";
import LanguageSelect from "components/layout/header/LanguageSelect";

ReactOnRails.register({
  "Header::Desktop::UserControls": Desktop.UserControls,
  "Header::Mobile::Navigation": Mobile.Navigation,
  "Header::LanguageSelect": LanguageSelect,
  "Header::Desktop::UserControls::Reactivation": Desktop.UserControls,
});
