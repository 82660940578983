// Borrowed from https://gist.github.com/andjosh/6764939

function easeInOutQuad(
  currentTime: number,
  startValue: number,
  valueChange: number,
  duration: number,
): number {
  currentTime /= duration / 2;
  if (currentTime < 1)
    return (valueChange / 2) * currentTime * currentTime + startValue;
  currentTime--;
  return (
    (-valueChange / 2) * (currentTime * (currentTime - 2) - 1) + startValue
  );
}

export default function animatedScrollTo(
  element: HTMLElement,
  to: number,
  duration: number,
): void {
  const start = element.scrollTop,
    change = to - start,
    increment = 20;

  let currentTime = 0;

  const animateScroll = (): void => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);

    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
}
