import { ScheduleTypeEnum } from "types";

export default {
  [ScheduleTypeEnum.WEEKLY]: {
    label: "Weekly",
    message: `
      Payments will be deducted once a week. Either four or five times a month.
    `,
  },
  [ScheduleTypeEnum.BI_WEEKLY]: {
    label: "Bi-Weekly",
    message: `
      Payments will be deducted every two weeks. In this schedule, there are 26
      pay periods a year, so some months may have two pay periods and some
      may have three.
    `,
  },
  [ScheduleTypeEnum.MONTHLY]: {
    label: "Monthly",
    message: "Payments will be deducted only once every month.",
  },
  [ScheduleTypeEnum.NTH_WEEKDAY]: {
    label: "Monthly Week and Day",
    message: `Payments will be deducted on the specific week and day of that week, once per month`,
  },
  [ScheduleTypeEnum.PARTICULAR_DAY]: {
    label: "Particular Day",
    message: `Payments will be deducted monthly on a particular day`,
  },
  [ScheduleTypeEnum.TWICE_MONTHLY]: {
    label: "Twice-Monthly",
    message: "Payments will be deducted twice a month.",
  },
};
