import * as React from "react";

import Dropdown, { PassedProps as DropdownProps } from "components/Dropdown";
import { UserControlPropsType as PropsType } from "components/layout/header/types";
import { getClasses } from "utils";

function LoggedIn(props: PropsType & Partial<DropdownProps>): JSX.Element {
  const { expanded, i18n, onTriggerClick, setTrigger, user, isReactivation } =
    props;

  const Lis = isReactivation ? (
    <li key={user.links[1].label}>
      <a
        className={`flex ${user.links[1].classname}`}
        data-method={user.links[1].method}
        rel="nofollow noopen"
        href={user.links[1].href}
      >
        <i aria-hidden="true" className={`fas fa-${user.links[1].icon_classname}`} />
        <span aria-hidden="true">{i18n[user.links[1].label]}</span>
      </a>
    </li>
  ) : (
    user.links.map(({ classname, href, icon_classname, label, method }) => (
      <li key={label}>
        <a
          className={`flex ${classname}`}
          data-method={method}
          rel="nofollow noopen"
          {...{ href }}
        >
          <i aria-hidden="true" className={`fas fa-${icon_classname}`} />
          <span aria-hidden="true">{i18n[label]}</span>
        </a>
      </li>
    ))
  );

  return (
    <div className={getClasses("tfs--dropdown user", { expanded })}>
      <button
        onClick={onTriggerClick}
        ref={setTrigger}
        type="button"
        className="button"
        aria-label="Account options dropdown menu toggle"
        aria-expanded={expanded}
      >
        <span>
          {i18n.welcome}, {user.name}
        </span>
        <i className="fas fa-caret-down" />
      </button>

      <ul>{Lis}</ul>
    </div>
  );
}

export default Dropdown(LoggedIn);
