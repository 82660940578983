import * as React from "react";

import { UserControlPropsType as PropsType } from "components/layout/header/types";

import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";

export default function UserControls(props: PropsType): JSX.Element {
  return !!props.user ? <LoggedIn {...props} /> : <LoggedOut {...props} />;
}
