/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default function getIn(obj: {}, pathParts: string[]): any {
  let node = obj;

  for (let i = 0; i < pathParts.length; i++) {
    const key = pathParts[i];

    if (node.hasOwnProperty(key)) {
      node = node[key];
    } else {
      return undefined;
    }
  }

  return node;
}
