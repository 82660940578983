/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function isObject(value: any): boolean {
  const type = typeof value;
  return value != null && (type == "object" || type == "function");
}

export default function getClasses(
  ...options: (string | undefined | {})[]
): string {
  const last = options[options.length - 1];
  const [lastClassIndex, conditions] = isObject(last) ? [-1, last] : [0, {}];
  const classList = options.slice(0, options.length + lastClassIndex);

  const conditionalClasses = conditions
    ? Object.keys(conditions).filter(key => !!conditions[key])
    : [];

  return [...classList, ...conditionalClasses]
    .filter(klass => !!klass)
    .join(" ");
}
