import * as React from "react";
import { Component, ComponentType } from "react";

interface StateType {
  expanded: boolean;
}

interface PassedProps extends StateType {
  closeDropdown(): void;
  onTriggerClick(): void;
  setTrigger(element: HTMLButtonElement): void;
}

function Dropdown<P>(Comp: ComponentType<P>): ComponentType<P> {
  return class DropdownWrappedComponent extends Component<P, StateType> {
    mounted = true;

    state = {
      expanded: false,
    };

    trigger: HTMLButtonElement;

    componentDidMount(): void {
      document.addEventListener("click", this.onDocumentClick);
      document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount(): void {
      this.mounted = false;
    }

    closeDropdown = (callback?: () => void): void => {
      if (!this.mounted || !this.state.expanded) return;
      this.setState({ expanded: false }, callback);
    };

    onDocumentClick = (event): void => {
      if (this.mounted && !this.trigger.contains(event.target)) {
        this.closeDropdown();
      }
    };

    onDocumentKeyDown = (event): void => {
      if (event.which === 27) this.closeDropdown();
    };

    onTriggerClick = (): void => {
      this.setState(({ expanded }) => ({ expanded: !expanded }));
    };

    setTrigger = (element: HTMLButtonElement): void => {
      this.trigger = element;
    };

    render(): JSX.Element {
      const {
        closeDropdown,
        onTriggerClick,
        setTrigger,
        state: { expanded },
      } = this;

      return (
        <Comp
          {...{ closeDropdown, expanded, onTriggerClick, setTrigger }}
          {...this.props}
        />
      );
    }
  };
}

export { Dropdown as default, PassedProps };
