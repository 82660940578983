import * as React from "react";

import Dropdown, { PassedProps as DropdownProps } from "components/Dropdown";
import { getClasses } from "utils";

import { LinkType } from "./types";
import { i18n } from "../../shared/i18n";

interface PropsType {
  links: LinkType[];
  locale: string;
}

function LanguageSelect(
  props: PropsType & Partial<DropdownProps>,
): JSX.Element {
  const { expanded, onTriggerClick, setTrigger, links, locale } = props;
  const Lis = links.map(
    ({ href, label }): JSX.Element => (
      <li
        className={getClasses({ active: window.location.origin === href })}
        key={label}
      >
        <a {...{ href }}>{label}</a>
      </li>
    ),
  );

  i18n.locale = locale;

  return (
    <div className={getClasses("tfs--dropdown language-select", { expanded })}>
      <button
        className="button--header"
        onClick={onTriggerClick}
        ref={setTrigger}
        type="button"
      >
        <i className="fas fa-language fixed" />
        <span>{i18n.t("language.button")}</span>
        <i className="fas fa-caret-down" />
      </button>

      <ul>{Lis}</ul>
    </div>
  );
}

export default Dropdown(LanguageSelect);
