import * as React from "react";
import { Component } from "react";

import {
  I18nType,
  LinkType,
  UserControlPropsType,
} from "components/layout/header/types";

import { getClasses } from "utils";

interface StateType {
  open: boolean;
}

interface PropsType {
  i18n: I18nType;
  images: {
    logo: string;
  };
  links: { root: LinkType };
  primary_links: LinkType[];
  secondary_links: LinkType[];
  user_control: UserControlPropsType;
}

export default class Navigation extends Component<PropsType, StateType> {
  public state = {
    open: false,
  };

  private LinkListItems = ({ links }: { links: LinkType[] }): JSX.Element => {
    const { i18n } = this.props;

    const Lis = links.map(
      ({
        active,
        classname,
        href,
        icon_classname,
        label,
        method,
        rel,
      }): JSX.Element => (
        <li className={getClasses("link", classname, { active })} key={label}>
          <a data-method={method} {...{ href, rel }}>
            <i className={`fas fa-${icon_classname}`} />
            <span>{i18n[label]}</span>
          </a>
        </li>
      ),
    );

    return <>{Lis}</>;
  };

  private SecondaryLinks = (): JSX.Element => {
    const {
      LinkListItems,
      props: { secondary_links },
    } = this;

    return <LinkListItems links={secondary_links} />;
  };

  private UserControls = (): JSX.Element => {
    const {
      LinkListItems,
      props: { user_control },
    } = this;

    const links = !!user_control.user
      ? user_control.user.links
      : user_control.session_links;

    return <LinkListItems {...{ links }} />;
  };

  private WelcomeMessage = (): JSX.Element => {
    const {
      i18n,
      user_control: { user },
    } = this.props;

    let message = i18n.welcome;

    if (this.isLoggedIn()) message += `, ${user.name}`;

    return <h2>{message}</h2>;
  };

  private isLoggedIn(): boolean {
    const { user_control } = this.props;
    return !!user_control.user;
  }

  private onCloseClick = (): void => {
    this.setState({ open: false });
  };

  private toggleOpen = (): void => {
    this.setState(({ open }) => ({ open: !open }));
  };

  public render(): JSX.Element {
    const {
      LinkListItems,
      SecondaryLinks,
      UserControls,
      WelcomeMessage,
      onCloseClick,
      props: { images, links, primary_links },
      state: { open },
      toggleOpen,
    } = this;

    return (
      <div className={getClasses("navigation", { open })}>
        <button
          className="button--transparent menu-trigger"
          onClick={toggleOpen}
          type="button"
        >
          <i className="fas fa-bars" />
          <i className="fas fa-caret-left open-state" />
        </button>

        <aside>
          <header>
            <a className="logo" href={links.root.href}>
              <img src={images.logo} alt="TFS" />
            </a>

            <WelcomeMessage />

            <button
              className="button--transparent close"
              onClick={onCloseClick}
              type="button"
            >
              <i className="fas fa-times" />
            </button>
          </header>

          <ul>
            <LinkListItems links={primary_links} />
            <SecondaryLinks />
            <li className="separator">
              <i className="fas fa-user-circle" />
            </li>
            <UserControls />
          </ul>
        </aside>
      </div>
    );
  }
}
